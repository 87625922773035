
*{
    margin: 0;
    padding: 0;
}
body{
    overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}
p{
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}
.TopBanner{
    background-image: url('../images/banner.png');
    min-height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

}
.TOpBannerOverlay{
    position: absolute;
    min-height: 650px;
    background-color: rgb(0 0 0 / 70%);
    width: 100%;
}
.TopContent{
    margin-top: 14em;
    z-index: 5;
    position: relative;
    color: #fff;
    text-align: center;
}
.ToptitleMain h1{
    font-size: 60px !important;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}
.TopsubTitle{
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-weight: 600;
    padding: 5px 0px;
}
.ToptitleMain button {
    width: 200px;
    height: 60px;
    border-radius: 0;
    font-size: 20px;
    border: 0;
    background-color: #1362d1;
    color: #ffffff;
}
.ToptitleMain button:hover{
    background-color: #ffffff;
    color:#1362d1;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
    box-shadow: none !important;
}
.serviceCard{
    padding: 60px 25px;
    border: 1px solid #0073E6;
    box-shadow: 0px 0px 16px #0073e630;
    text-align: center;
}
.ServiceMainTitle{
    color: #0a58ca;
    padding: 70px 0px;
    text-align: center;
    font-family:'Montserrat', sans-serif;
    text-transform: uppercase;
}
.ServiceMainTitle h2 {
    font-weight: 700;
}
.serviceName{
    padding: 15px 0px;
    font-size: 22px;
    font-family: 'Raleway', sans-serif;
}
.serviceDescription{
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    
}
.NavTitle{
    font-size: 30px;
    font-family:'Montserrat', sans-serif;
    font-weight: 700;
    color: #ffffff !important;
}
.NavTitleScroll{
    font-size: 30px;
    font-family:'Montserrat', sans-serif;
    font-weight: 700;
    color: #0a58ca !important;
    background-color: transparent;
}
.NavBackGround{
    background-color: transparent !important;
    transition: background-color 400ms linear;
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -ms-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
}
.NavBackGroundScroll{
    background-color: #ffffff !important;
    transition: background-color 400ms linear;
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -ms-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
}
.navItem{
    font-size: 16px ;
    text-decoration: none;
    font-family:'Montserrat', sans-serif;
    font-weight: 400;
    color: #ffffff ;
}
.navItemScroll{
    font-size: 16px;
    text-decoration: none;
    font-family:'Montserrat', sans-serif;
    font-weight: 400;
    color: #000000 !important;
}
.navbar-nav a {
    color: #f8f9fa;
    padding: 0px 0px 0px 40px !important;
    font-weight: 500;
}
nav.NavBackGroundScroll.navbar.navbar-expand-lg.navbar-light.fixed-top {
    box-shadow: 0px 0px 8px #ddd;
}
.analysis-text p{
    text-align: justify;
    
}
BarChart{
    width: 100%;
    height: 300px;
}
.SummeryBanner{
    background-image: url('../images/summery.jpg');
    min-height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    margin-top: 5rem;

}
.SummeryBannerOverlay{
    position: absolute;
    min-height: 350px;
    background-color: rgb(0 0 0 / 70%);
    width: 100%;
}
span.borderBtm {
    border-bottom: 1px solid #ffffff;
    width: 100px;
    display: inline-block;
    text-align: center;
}
.projectsItems{
    color: #ffffff;
    margin-top: 65px;
}
.HowItWorks {
    background-color: #ffffff;
    padding: 40px 25px;
    text-align: left;
}
.HowItWorks h3{
    text-align: left;
    display: block;
}
.HowItWorks ul li{
    list-style-type: none;
    position: relative;
    padding-left: 20px;
}
.HowItWorks ul li::after{
    content: '';
    background-image: url('../images/check.png');
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 4px;
    width: 15px;
    height: 15px;
    background-size: 100%;
    background-position: left ;

}
.SummeryMain{
    padding-top: 60px;
}
.HowItWorks ul {
    padding-left: 0;
}
.CourseImg{
    width: 100%;
}
.CourseImagesRc img{
    width: 100%;
    padding: 10px;
}
.CourseContent{
    text-align: left;
}
.CourseContent a{
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
    display: inline-block;
}
.CourseContent p{
    font-family:'Montserrat', sans-serif;
    font-weight: 400;
    padding: 0;

}
.HowiDoItems {
    border: 1px solid rgb(13 110 253 / 25%);
    box-shadow: 0px 0px 6px rgb(13 110 253 / 25%);
    padding: 70px 60px;
    text-align: center;
}
.HowiDoItems h4{
    padding-bottom: 20px;
    color: #0d6efd ;
    font-size: 30px;
}
.HowiDoItems Button{
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}
.playBtn{
    font-size: 50px;
    color: #0d6efd;
}
.HowiDoItems p{
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
}
.circleImg{
    width: 100px;
    height: 100px;
    border: 1px solid #0d6efd;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    text-align: center;
    display: inline-block !important;
    margin-bottom: 15px;
    margin-top: 10px;
}
.FooterSection {
    background-color: #0a80f5;
    margin-top: 70px;
    padding-bottom:20px;
}
.FooterBottoms{
    background-color: #0a80f5;
}
.FooterBorder{
    border-top: 1px solid #ffffff;
    padding: 20px 0px;
}
.FooterBorder p{
    color: #ffffff;
    margin: 0;
}
.FooterDescription {
    text-align: left;
}
.FooterTitle h4 {
    color: #ffffff;
}
.FooterDetailsItem a {
    display: block;
    text-align: left;
    color: #ffffff;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
}
.FooterDetailsItem a:hover{
    color: #e9ecef;
}
.FooterDetailsItem p {
    color: #ffffff;
    
}
.FooterDetailsItem ul {
    margin: 0;
    padding: 0;
}
.FooterDetailsItem ul li{
    list-style-type: none;
    padding: 4px 0px;
}
.FooterDetailsItem svg {
    color: aliceblue;
    font-size: 15px;
    margin-right: 3px;
}
.FooterTitle h4 {
    padding-bottom: 15px;
}
.PageTopBanner{
    background-image: url('../images/bradcumd-1.jpg');
    min-height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

}
.PageTOpOverlay{
    position: absolute;
    min-height: 350px;
    background-color: rgb(0 0 0 / 70%);
    width: 100%;
}
.PageTopContent{
    margin-top: 9em;
    z-index: 5;
    position: relative;
    color: #fff;
}
.PageTopTitle h5 {
    font-size: 40px;
    text-transform: uppercase;
}
/*About Css*/
.AboutText {
    text-align: left;
    margin: 70px 0px;
}
.AboutText h4{
    font-size: 30px;
}
.ContactDesc p, .ContactTi h4{
    color: #222222;
}
.ContactDesc svg {
    color: #222222;
}
.COntactBtn{
    width: 150px;
    height: 50px;
    border: 1px solid #0d6efd;
    background-color: #0d6efd;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
}
.COntactBtn:hover{
    background-color: transparent;
    color:#0d6efd;
}